
.tag {
  font-size: .9rem;
  font-weight: 900;
  color: var(--l);
  background-color: var(--p);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  padding: 3px 5px;
  margin: 3px;
  width:fit-content;
  display: inline-block;
  padding:0.1em 0.5em;
  transition: all 500ms;
  cursor: default;
}
.taglist:not(td .taglist):not(.b1 .taglist){
  position: absolute;
  bottom: 0;
  text-align: left;
  display: inline;
  padding-left:0.5em;
  padding-bottom:0.5em;
  min-width: none;
  max-width: none;
  min-width: none;
}
.tagedit{
  display: inline;
  padding-left:0.5em;
  padding-bottom:0.5em;
  min-width: none;
  max-width: none;
  width: auto;
}
.myupload .taglist{
  position:relative !important
}
.tags >div button{
  font-size:0.8em !important;
  margin-left: 1px;
}
.tags{
  position: relative;
}
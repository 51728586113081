
.packageFile {
  background-color: var(--l);
  color: var(--p);
  border-radius: var(--radius);
  margin: 1rem;
  padding: 1rem;
  width: auto;
  min-width: 80%;
  transition: var(--transitionIn);
  box-shadow: var(--shadow);
  border: var(--lightBorder);
}


.listedPackage {
  background-color: var(--l);
  color: var(--p);
  border-radius: var(--radius);
  padding: 1rem;
  width: auto;
  min-width: 80%;
  transition: var(--transitionIn);
  cursor: pointer;
  border: var(--transBorder);
  text-align: center;
}

.listedPackage:hover {
  border: var(--border);
  transition: var(--transitionOut);
}

.listedPackage h3 {
  color: var(--d);
  cursor: pointer;
}

.listedPackage span {
  cursor: pointer;
}

.listedPackage p {
  cursor: pointer;
}


#packageTitles {
  width: auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px;
  max-width: 100%;
  max-height: 70vh;
  min-height: 70vh;
  border-radius: var(--radius);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: var(--border);
  border-left: none;
  box-shadow: var(--shadow);
  background-color: var(--l);
  grid-gap: 3px;
}

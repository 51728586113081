.selectedFile {
  background-color: var(--l);
  color: var(--d);
  font-weight: 800;
  border: var(--border);
}

#uploadinfo {
  position: fixed;
  bottom: 20px;
  background-color: var(--d);
  color: var(--l);
  box-shadow: 1px 2px 3px #00000040;
  border-radius: 10px;
  border: 2px solid var(--s);
  padding: 5px 7px;
}

#window {
  background-color: var(--l);
  color: var(--d);
  max-width: 100%;
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  border: 2px solid var(--s);
}

.fileFolder {
  background-color: var(--d);
  color: var(--l);
  transition: 300ms ease;
}

.folder:hover {
  background-color: var(--p)!important;
  color: var(--l)!important;
}

#selectedFiles {
  width: auto;
  padding: 20px;
  border-radius: var(--radius);
  background-color: var(--l);
  border: var(--border);
  color: var(--d);
  box-shadow: var(--shadow);
  max-height: 60vh;
  overflow-y: scroll;
}

#selectedFileInfo {
  grid-area: 1 / 1 / span 1 / span 3;
}

.dropper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 90vh;
  overflow-y: scroll;
  transition: 100ms ease;
  border-radius: 10px;
}

.backed {
  background-color: var(--p);
  box-shadow: 1px 2px 3px #00000040;
  color: var(--l);
  font-size: 1.5rem;
  transition: 150ms ease;
  border: 1px solid var(--l);
}

#uploadinfo {
  position: fixed;
  bottom: 20px;
  background-color: var(--d);
  color: var(--l);
  box-shadow: 1px 2px 3px #00000040;
  border-radius: 10px;
  border: 2px solid var(--s);
  padding: 5px 7px;
}

.folder:hover {
  background-color: var(--g);
  color: var(--p);
}

.popgrid {
  position: relative;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 10%;
}

.dropper #searchbar {
  position: absolute;
  top: 20px;
  left: 0;
}
#file {
  position: relative;
}
.sortGrid button:hover {
  background-color: var(--p);
  color: var(--l);
}
.sortGrid .activebutton:hover {
  background-color: var(--l);
  color: var(--p);
}
.treeFolder:hover {
  background-color: var(--p);
  color: var(--l);
}
#fileManager .options {
  top: 5px;
  right: 5px;
  left: auto;
}

.back {
  position: absolute;
  left: 5px;
  top: 50px;
  z-index: 0;
}
@media screen and (max-width: 850px) {
  .popgrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .folder {
    min-height: none;
  }
}

#fileNotif {
  position: fixed;
  bottom: 0;
  z-index: 4;
}

.fileTitle {
  /*  background-color: var(--p);*/
  background-color: var(--s);
  color: var(--l);
  padding: 8px;
  border-radius: var(--radius);
  border: 1px solid var(--g);
  box-shadow: var(--shadow);
}

#fileManager {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 5fr;
}

#directoryTree {
  justify-self: flex-start;
  align-self: flex-start;
  width: auto;
  max-width: 100%;
  padding: 10px;
  margin: 10px 0;
  margin-top: 10vh;
  overflow: hidden;
  background-color: var(--l);
  color: var(--d);
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 2px solid var(--s);
}
.pImage {
  max-width: 50%;
  max-height: 30vh;
}
.listFile {
  max-width: 100%;
  padding: 10px;
  border: var(--border);
  background-color: var(--l);
  color: var(--d);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transitionIn);
  position: relative;
}

.listFile * {
  cursor: pointer
}

.listFile h3 {
  word-wrap: break-word;
  display: inline-block;
  width: 70%;
}

#fileSelection {
  display: grid;
  width: 100%;
  max-width: 100%;
  grid-template-columns: 1fr 3fr;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  grid-gap: 20px;
}

#fileInfo {
  z-index: 2;
}

.listFile:hover {
  background-color: var(--p);
  color: var(--l);
  border: var(--transBorder);
  transition: var(--transitionOut);
}

#filePop {
  background-color: var(--p);
  border: var(--border);
  border-radius: var(--radius);
  min-width: 200px;
  max-width: 40%;
  box-shadow: var(--shadow);
}

#filePop div {
  background-color: var(--p);
  font-weight: 800;
  margin: 4px 0;
  color: var(--l);
  padding: 4px;
  cursor: pointer;
  transition: var(--transitionIn);
  text-align: left;
}

#filePop div:hover {
  background-color: var(--l);
  margin: 4px 0;
  color: var(--p);
  padding: 4px;
  cursor: pointer;
  transition: var(--transitionOut);
}

.selectedFile {
  background-color: var(--d);
  color: var(--l);
}

#drop_zone {
  width: auto;
  min-width: 80%;
  min-height: 25vh;
  padding: 20px;
  border-width: 2px;
  border-radius: var(--radius);
  border-color: var(--p);
  border-style: dashed;
  cursor: pointer;
  background-color: var(--l);
  color: var(--d);
  outline: none;
  transition: var(--transitionIn);
  box-shadow: var(--shadow);
}

#drop_zone i {
  color: var(--g) !important;
  font-size: 3em;
  padding-top: 0.5em
}

#drop_zone:hover {
  background-color: var(--p);
  border-color: var(--g);
  color: var(--l);
}

@media screen and (max-width: 850px) {
  #fileManager {
    grid-template-columns: 1fr;
  }
  #directoryTree {
    grid-area: 2 / 1 / span 1 / span 1;
  }
}

.treeFolder {
  padding-left: 10px;
  max-width: 100%;
}
.treeFolder, .treeFolder h3 {
  cursor: pointer;
  text-align: left;
}

.folder {
  clip-path: polygon(0 0, 30% 0, 34% 10%, 100% 10%, 100% 100%, 0 100%);
  background-color: var(--b);
  color: var(--l);
  box-shadow: var(--shadow);
  min-height: 8vh;
}

.folder:hover {
  background-color: var(--g);
  color: var(--d);
}

.folder button {
  border: 2px solid transparent;
  color: black;
  opacity: 0;
  transition: all 500ms;
  left: -10px;
  top: -10px;
  position: absolute;
  width: 30px;
  height: 30px;
  background: var(--s);
  color: var(--l)!important;
  box-shadow: none;
  line-height: 15px;
}

.drag-over button {
  display: none !important;
}

.folder button i {
  color: var(--l);
  pointer-events: none;
}

.folder:hover button {
  transition-delay: 500ms;
  color: var(--l);
  opacity: 1;
}

.breadcrumbs i {
  padding: 0 1em
}

.breadcrumbs span {
  cursor: pointer;
}

.breadcrumbs span:hover {
  font-weight: 700;
}

.folder h3, .file h3 {
  pointer-events: none;
}

#filePop div:hover .submenu {
  opacity: 1 !important;
  pointer-events: all;
}

.submenu {
  position: absolute;
  left: 195px;
  margin-top: -35px !important;
  background-color: var(--p);
  border: var(--border);
  border-radius: var(--radius);
  min-width: 200px;
  max-width: 40%;
  box-shadow: var(--shadow);
  opacity: 0;
}

#filePop div .submenu:hover {
  background-color: var(--p);
  cursor: pointer;
  transition: var(--transitionOut);
}

.fileFolder {
  background-color: var(--s);
}
#filecont {
  margin-top: 50px;
}
@media screen and (max-width: 1080px) {
  #fileManager .options {
    display: none!important;
    width: 80px;
    padding: 10px;
    max-width: 33%;
    bottom: 0px;
    top: auto;
    right: auto;
    left: 0px;
    background-color: var(--p);
    color: var(--l)!important;
    box-shadow: 1px 2px 3px #00000040;
    z-index: 4;
    border-radius: var(--radius);
    border-top-right-radius: 0;
    position: fixed;
  }
  .options #sortingMode .sortGrid button {
    background-color: var(--g)!important;
    color: var(--d);
  }
}
@media screen and (max-width: 850px) {
  #fileManager {
    padding-top: 50px;
  }
  #fileManager .options {
    max-width: 80%;
    padding: 2px;
  }
}
#nav #notificationIcon {
  fill: var(--l);
}

#nav #notificationIcon:hover {
  fill: var(--s);
}

#notificationIcon path {
  fill: var(--l);
}

#notificationIcon {
  position: relative;
  max-width: 40px;
  max-height: 40px;
  padding: 5px;
  overflow: visible;
  cursor: pointer;
  transition: var(--transitionIn)
}

#notificationIcon:hover {
  fill: var(--l);
  transform: scale(.9, .9);
  transition: var(--transitionOut);
}

#notificationCount {
  position: absolute;
  font-size: .8rem;
  top: -3px;
  right: -3px;
  background-color: var(--s);
  color: var(--l);
  font-weight: 900;
  border-radius: 100%;
  box-shadow: var(--shadow);
  padding: 2px 5px;
  font-family: Arial, Helvetica, sans-serif, roboto;
  text-shadow: 1px 2px 3px #00000040;
}

#notificationCenter {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: var(--l);
  top: 0;
  padding: 10px;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: scroll;
}

#notificationCenter ul li:first-of-type {
  margin-top: 4em
}

#notificationList {
  width: 300px;
  min-width: 90%;
  max-width: 100%;
  padding: 10px;
  overflow: none;
}

#notificationCenter ul, #notificationList ul, #uploadList ul {
  list-style-type: none;
}

#notificationCenter ul li i:not(.closebtn), #notificationList ul li i:not(.closebtn), #uploadList ul li i:not(.closebtn):not(.tagicon) {
  display: inline-block;
  left: 1em;
  font-size: 1.6rem
}

#notificationCenter ul li i.closebtn, #notificationList ul li i.closebtn, #uploadList ul li i.closebtn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.6rem;
  color: var(--p);
  transition: var(--transitionIn);
}

#notificationCenter ul li div, #notificationList ul li div, #uploadList ul li div {
  display: inline-block;
}
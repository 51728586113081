
.titleSelect {
  border: 2px solid var(--l);
  background-color: var(--l);
  color: var(--p);
  padding: 10px;
  max-width: 100%;
  width: auto!important;
  border: var(--transBorder);
  min-width: 80%;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  cursor: pointer;
  transition: var(--transitionIn);
  min-height: 130px;
  max-height: 200px;
  position: relative
}
#titleInfo {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}
#titleMetadata {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 10px;
  width: 100%;
  max-width: 100%;
}
#titleoption h3 {
  cursor: pointer;
}
#titleoption h3:hover {
  color: var(--p);
  border-bottom: 1px solid var(--p);
}
.titleSelect h2 {
  text-transform: uppercase;
}

.titleSelect * {
  cursor: pointer;
}

.titleSelect:hover {
  background-color: var(--p);
  color: var(--l);
  border: var(--border);
  transition: var(--transitionOut);
}

.titleSelect:hover h2 {
  color: var(--s);
  /* background-color: var(--l);
 padding: 5px 7px;
 box-shadow: var(--shadow);
 border-radius: var(--radius); */
  transition: var(--transitionOut);
}
#metadataList {
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  padding: 10px;
}
.selectedTitle {
  background-color: var(--p);
  color: var(--l);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  overflow: hidden;
  padding: 5px;
}

/* poster frame */


.poster {
  max-width: 100%;
  max-height: 70vh;
  border-radius: var(--border);
  box-shadow: var(--shadow);
  background-color: var(--l);
}
.poster img {
  max-width: 100%;
  max-height: 45vh;
  box-shadow: var(--shadow);
}

.poster .border{
  border:0;
  width:100%;
  max-width: 400px;
  box-sizing: border-box;
  position: relative;
  background: black;
  background-image: linear-gradient(top right, #5D5D5B, #383838);
  width: 220px;
  box-shadow:
    -1px 1px var(--blur) 1px rgba(0,0,0,0.10),
    -2px 2px var(--blur) 1px rgba(0,0,0,0.09),
    -3px 3px var(--blur) 1px rgba(0,0,0,0.08),
    -4px 4px var(--blur) 1px rgba(0,0,0,0.07),
    -5px 5px var(--blur) 1px rgba(0,0,0,0.06),
    -6px 6px var(--blur) 1px rgba(0,0,0,0.05),
    -7px 7px var(--blur) 1px rgba(0,0,0,0.04),
    -8px 8px var(--blur) 1px rgba(0,0,0,0.03),
    -9px 9px var(--blur) 1px rgba(0,0,0,0.03),
    -10px 10px var(--blur) 1px rgba(0,0,0,0.03),
    -11px 11px var(--blur) 1px rgba(0,0,0,0.03),
    -12px 12px var(--blur) 1px rgba(0,0,0,0.02),
    -13px 13px var(--blur) 1px rgba(0,0,0,0.02),
    -14px 14px var(--blur) 1px rgba(0,0,0,0.01),
    -15px 15px var(--blur) 1px rgba(0,0,0,0.01),
    -16px 16px var(--blur) 1px rgba(0,0,0,0.01);
}
  .poster .border:before{
    content: ' ';
    display: block;
    padding-bottom: 140%;
  }


  .poster .frame{
  left: 3%;
  top: 2.5%;
  box-shadow: inset -1px 1px 6px 1px rgba(0,0,0,.24);
  width: 94%;
  height: 95%;
  background: white;
  align-items: center;
  display: flex;
  padding: 18px;
  box-sizing: border-box;
  position: absolute;
}
#backtotitles {
  cursor: pointer;
}
#backtotitles:hover {
  color: var(--p);
}
 .poster .image{
  box-shadow: inset 0 0 1px 0 rgba(0,0,0,.2);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

@media screen and (max-width: 650px) {
  #titleMetadata {
    grid-template-columns: 1fr;
  }
}
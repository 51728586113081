.accordian {
  width: auto;
  max-width: 100%;
  min-width: 90%;
  padding: 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  width: 100%;
}

.accordianHolder h3 {
  text-align: left;
  width: auto;
  max-width: 100%;
  min-width: 95%;
  padding: 10px;
  transition: var(--transitionIn);
  border-radius: var(--radius);
  padding: 0;
  margin: 0;
  font-weight: 900;
  cursor: pointer;
  color: var(--p)
}

.accordianHolder .jfs {
  transition: var(--transitionIn);
}

.accordianHolder .jfs:first-of-type {
  cursor: pointer !important;
  text-align: center;
  display: block;
  padding: 0.5em
}


.accordianContent {
  border-top: 1px solid var(--p);
  height: auto;
  padding: 1em;
}

.accordianContent .b2{
  width: 100%;
  display: block;
}

.accordianHolder * a:hover {
  font-weight: 900;
}

.accordianHolder  .b2 i.fal {
  position: absolute;
  right: 1em;
  top: 0.4em;
  font-size: 2em;
}

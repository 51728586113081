* {
    padding: 0;
    margin: 0;
    
}
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);*/
    width: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--p);
    outline: 1px solid var(--l);
}
:root {
    --l: #ffffff;
    --d: #000200;
    --p: #0f245b;
    --po: #0f245b80;
    --w: #d67e1e;
    --s: #ba9877;
    --g: #d7d7d7;
    --a: #efefef;
    --b: #333333;
    --r: #dc3545;
    --gr: #48d6b3;
    --f: #EAD87D;
    --dw: #efefef;
    --delivered: rgb(44, 160, 62);
    --processing: rgb(234, 141, 249);
    --review: rgb(249, 206, 141);
    --marketing: rgb(141, 152, 249);
    --onboarding: #409fae;
    --radius: 8px;
    --border: 2px solid var(--p);
    --lightBorder: 1px solid var(--p);
    --transBorder: 2px solid transparent;
    --shadow: 1px 2px 3px #00000040;
    --transitionIn: 300ms ease-in;
    --transitionOut: 250ms ease-out;
}

.hidden {
    display: none!important;
}

.warn {
    color: var(--w)!important;
}

.pushed {
    width: auto!important;
    min-width: 90%;
    max-width: 100%;
    padding: 10px!important;
    border-radius: var(--radius);
}

.pushed2 {
    min-width: 90%;
    max-width: 100%;
    padding: 10px!important;
    width: 100%!important;
}
#side {
    position: relative;
    display: grid;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    overflow:hidden;
    transition: 400ms ease;
}
.full {
    min-height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    margin-top: 0!important;
    margin-bottom: 0!important;
    overflow-y: scroll;
    justify-content: flex-start!important;
    width: 100%;
    max-width: 100%;
}
#sidebar {
    background-color: var(--p);
    color: var(--l);
    transition: 400ms ease;
    max-height: 100vh;
    height: 100%;
}
body {
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow-y: scroll;
    color: var(--d);
    font-size: 1rem;
    font-family: roboto, Helvetica, Arial, sans-serif;
    word-break: break-word; 
}

#app {
    max-width: 100%;
    padding: 0;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    min-width: 94%;
    color: var(--d);
    position: relative;
    overflow-x: hidden;
    background-color: var(--l);
}

a {
    color: #0f245b;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    color: #0f245b;
    text-decoration: underline;
}

.rel {
    position: relative;
}



.meg {
    max-width: 50px;
}
.told .b1 {
 margin: 0;
}
.flexer {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-self: center;
    align-self: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}
.cento {
    justify-self: center!important;
    align-self: center!important;
    justify-content: center!important;
    align-items: center!important;
}
.flexer .b1 {
    display: inline-flex;
    flex-direction: column;
    align-items: center!important;
    justify-content: center!important;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}
.flexer .b2 {
    display: inline-flex;
    flex-direction: row;
    align-items: center!important;
    justify-content: center!important;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}
.formtop {
    margin-top: 5vh!important;
}
#nav *.active {
    background-color: var(--l);
    color: var(--p)
}
#sidebar *.active {
    background-color: var(--l);
    color: var(--p)
}
#sidebar button:hover {
    background-color: #ffffff90;
}
.minialert {
    display: grid;
    grid-template-columns: 1fr 4fr;
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: 5px;
    border: var(--lightBorder);
    box-shadow: var(--shadow);
}

.minialert p {
    font-weight: 800;
}

.fs {
    justify-content: flex-start!important;
}

#refresh {
    position: absolute;
    bottom: 25px;
    right: 10px;
    z-index: 2;
    max-width: 33%;
}

#footer {
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    max-height: 40vh;
    padding: 20px 0;
    background-color: var(--p);
    color: var(--l);
}

#nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100px;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    padding: 10px 0;
    background-color: var(--p);
    color: var(--l);
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    justify-content: center;
    animation: slidedown 300ms ease-out;
    z-index: 9;
}

#mobilenav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    max-width: 100%;
    margin: 0;
    padding: 10px 0;
    background-color: var(--p);
    color: var(--l);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slidedown 400ms ease-out;
    z-index: 9;
}

#mobNavClose {
    font-size: 1.1rem;
    font-weight: 800;
    color: var(--g);
    background-color: var(--p);
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px;
    border-bottom-left-radius: 3px;
    z-index: 10;
    cursor: pointer
}

#mobNavOpen {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom-right-radius: 12px;
    box-shadow: var(--shadow);
    background-color: var(--p);
    color: var(--l);
    font-weight: 800;
    font-size: 1.9rem;
    z-index: 8;
    cursor: pointer;
    padding: 10px 20px;
}

#nav #logo {
    width: 300px;
    max-width: 100%;
    overflow: hidden;
    padding: 0 1em;
    margin-top: 4px;
}
#sidebar #logo {
    max-width: 100%;
    overflow: hidden;
    padding: 10px;
    margin-top: 10px;
}
.navtop {
    margin: -40px 0;
    justify-content: flex-start;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
}
.pVideo {
	--vidW: 550px;
	width: var(--vidW);
    max-width: 100%;
	max-height: calc((9/16)*var(--vidW));
	border-radius:5px;
}
@media screen and (max-width: 1360px) {
	.pVideo {
		--vidW: 350px;
	}
}
@media screen and (max-width: 850px) {
	.pVideo {
		--vidW: 290px;
	}
}
@media screen and (max-width: 650px) {
	.pVideo {
		--vidW: 250px;
	}
}
@media screen and (max-width: 910px) {
    .margy {
        margin-left: 10px!important;
    }
    .margy.fifty {
        width: auto;
        min-width: 30%;
    }
}
#waiting {
    position: fixed;
    top: 0;
    left: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 98vh;
    max-height: 100vh;
    background-color: var(--po);
    color: var(--l);
    z-index: 100000000;
}
.navcent {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  justify-content: "center";
  height: 'auto';
  margin: 40px 0;
}
.navbot {
    justify-content: flex-end;
    margin-top: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
}
#sidebar button {
    font-size: .8rem;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 5px;
    margin: 10px 0;
    box-shadow: none;
}
#sidebar button span {
    font-size: inherit;
    margin: 0;
    margin-left: 5px;
    padding: 0;
}
stacked-logo {
    max-width: 50px;
}
.navIcon {
    max-width: 20px;
    max-height: 20px;
}
#openSidebar {
    cursor: pointer;
    margin-top: 10px;
}
#selectedTitles {
    max-width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
}
#selectedFiles {
    min-width: 90%;
}
.tableSelection {
    width: 100%;
    max-width: 100%;
}
#mobilenav #logo {
    width: 60%;
    max-width: 60%;
    padding-top: 30px;
    overflow: hidden;
}

.QR {
    border-radius: var(--radius);
    border: var(--border);
    max-width: 33%;
}

.cent {
    width: auto;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.selectedTitleB {
    font-weight: 800;
    font-size: 1.1rem;
    color: var(--l);
    background-color: var(--p);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
}
#popup, #popup2, #popup3 {
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--p);
    color: var(--l);
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    animation: fadein 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

#pop, #pop2, #pop3 {
    max-width: 100%;
    padding-bottom: 100px;
}

/* #pop::-webkit-scrollbar, #pop2::-webkit-scrollbar, #pop3::-webkit-scrollbar {
  display: none!important;
} */

.closer {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 15;
    font-size: 1.1rem;
    background-color: var(--p);
    border: 2px solid var(--l);
    padding: 2px 5px;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transitionIn);
}

.closer:hover {
    transition: var(--transitionOut);
    color: var(--g);
}

.b1 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
}

.b2 {
    display: inline-flex;
    align-items: center;
    /* justify-content: center; */
    max-width: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
}

.fifty .b1 {
    max-width: 40%;
    width: auto;
    padding: 10px;
    margin: 0;
}

h1 {
    font-size: 2rem;
    max-width: 100%;
    padding: 10px;
    cursor: default;
    font-weight: 900;
}

h2 {
    font-size: 1.5rem;
    max-width: 100%;
    cursor: default;
    padding: 10px;
    font-weight: 900;
}

h3 {
    font-size: 1.2rem;
    max-width: 100%;
    cursor: default;
    padding: 5px;
    font-weight: 900;
}

span {
    /* font-weight: 400;
    max-width: 100%;
    cursor: default;
    padding: 5px; */
    /* font-size: 12px; */
}

p {
    font-weight: 400;
    max-width: 100%;
    cursor: default;
    padding: 5px;
    margin: 0;
}

label {
    font-size: .9rem;
    font-weight: 800;
    max-width: 100%;
    cursor: default;
    padding: 5px;
}

button {
    font-size: 12px;
    padding: 8px 15px;
    word-break: keep-all;
    text-transform: uppercase;
    border-radius: 0;
    background-color: var(--p);
    color: var(--l);
    transition: var(--transitionIn);
    box-shadow: var(--shadow);
    border: 2px solid var(--p);
}

button:hover {
    cursor: pointer;
    border: var(--border);
    transition: var(--transitionOut);
    color: var(--p);
    background-color: var(--l);
}
.selectedGenre {
    background-color: var(--p);
    color: var(--l);
    margin: 5px 10px;
    padding: 5px 7px;
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    cursor: default;
}
.selectedGenre .xButton {
    background-color: var(--r);
    padding: 2px;
    border-radius: var(--radius);
    margin-left: 2px;
    font-size: .6rem;
    cursor: pointer;
}
.checklist {
    max-width: 100%;
    min-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    border: var(--lightBorder);
    grid-gap: 5px;
    padding: 10px 0;
    background-color: var(--g);
    overflow-y: scroll;
    overflow-x: hidden;
}

.progress {
    margin-top: 10px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.14));
    background-color: #797979;
    width: 90%;
}

.progress-bar {
    background-color: #ba9877;
}

.progress-bar span {
    display: contents
}

.wpeo {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.wpeo .b1 {
    max-width: 66%;
}

.item {
    min-width: 90%;
    max-width: 100%;
    background-color: var(--l);
    color: var(--p);
    border: var(--lightBorder);
    padding: 5px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 5px;
}

.adminItem {
    min-width: 90%;
    max-width: 100%;
    background-color: var(--l);
    color: var(--p);
    border: var(--lightBorder);
    padding: 5px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 5px;
}

.item .icon {
    max-width: 30%;
    border: var(--lightBorder);
    border-radius: var(--radius);
    overflow: hidden;
    cursor: pointer;
    background-color: var(--l);
    box-shadow: var(--shadow);
    padding: 10px;
    margin-left: 5px;
    transition: var(--transitionIn);
}

.adminItem .icon {
    max-width: 30%;
    border: var(--lightBorder);
    border-radius: var(--radius);
    overflow: hidden;
    cursor: pointer;
    background-color: var(--l);
    box-shadow: var(--shadow);
    padding: 10px;
    margin-left: 5px;
    transition: var(--transitionIn);
}

.checkInput {
    display: grid;
    grid-template-columns: 3fr 1fr;
    max-width: 100%;
    padding: 10px;
    grid-gap: 10px;
    overflow: hidden;
}

.n form, .t {
    padding: 10px;
    width: auto!important;
    max-width: 100%!important;
    min-width: 90%!important;
    background-color: var(--l);
    color: var(--d);
    border: var(--border);
    border-radius: var(--radius);
    animation: slideup 350ms cubic-bezier(1, 0, 0, 1);
}

.n form, .t .b2 {
    width: 100%;
    justify-content: center;
}

.l {
    background-color: var(--l);
}

#MICOR {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0;
    left: 0;
}
.wide {
    min-width: 95%;
}
input, textarea, select {
    border-radius: var(--radius);
    border: var(--lightBorder);
    max-width: 100%;
    margin: 5px 0;
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 800;
    text-align: left;
}

.fading {
    transition: var(--transitionIn);
}

.fading:hover {
    transition: var(--transitionOut);
    opacity: .7;
    transform: scale(.9, .9);
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
    width: auto;
    min-width: 80%;
    padding: 2rem;
    margin: 0;
    grid-gap: 10px;
}

.c1 {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-gap: 10px;
}

.c2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-gap: 10px;
}

.c3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-gap: 10px;
}
.c4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-gap: 10px;
}

ul li p {
    text-align: left !important;
}

#nav button {
    border: 1px solid var(--l);
    background-color: var(--p);
    color: var(--l);
    font-size: .9rem;
}

#mobilenav button {
    border: 1px solid var(--l);
    background-color: var(--p);
    color: var(--l);
    font-size: .8rem;
    font-weight: 800;
}

#searchbarInput {
    color: var(--p);
    text-align: left;
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    background-color: var(--l);
    margin: 0;
}

.sortGrid {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
}

.sortGrid button {
    font-size: .8rem;
    font-weight: 400;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    color: var(--d)
}

.sortGrid button.activebutton {
    background-color: var(--p);
    color: var(--l)
}

#sortingMode {
    display: inline-flex;
    flex-direction: column;
    border-radius: var(--radius);
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 10%;
    position: relative;
}

#sorter {
    display: inline-block;
    position: relative;
    font-size: 0.8rem
}

#searchbar {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: auto;
    min-width: 25%;
    max-width: 100%;
    position: relative;
    border-top: none;
    top: 0;
    left: 0;
    width: 300px !important;
}

#searchbar input[type="text"] {
    position: relative;
    font-family: roboto, Helvetica, Arial, sans-serif, 'FontAwesome';
}

#searchbar:before {
    font-family: 'FontAwesome';
    color: var(--p);
    position: absolute;
    left: 1em;
    content: "\f002";
    z-index: 1;
}

#searchbarInput::placeholder {
    font-size: .8rem;
    font-weight: 400;
    color: var(--p);
    padding-left: 2em;
    font-family: roboto, Helvetica, Arial, sans-serif;
}

.searchResult {
    max-width: 95%;
    min-width: 0;
    width: auto;
    border-radius: var(--radius);
    text-align: left !important;
    margin-bottom: 1em;
    cursor: pointer;
    position: relative;
}

.searchResult * {
    cursor: pointer;
}

textarea:focus, input:focus {
    outline-color: var(--p);
}

.searchResult .fa-angle-right {
    position: absolute;
    height: 100%;
    right: 1em;
    top: 30%;
    color: var(--g)
}

.searchResult:hover {
    background: var(--g)
}

.searchResult>h3 {
    width: fit-content;
    margin-bottom: 0;
}

#searchresults {
    background-color: var(--l);
    border: var(--border);
    border-radius: var(--radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px 0;
    margin: 0;
    z-index: 2;
    color:black;
}

#searchresults>div {
    width: 100%
}

#home {
    width: auto;
    min-width: 90%;
    max-width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px;
    grid-gap: 10px;
    margin: 0;
    display: grid;
    position: relative;
    grid-template-columns: 1fr 4fr;
}

.smallbut {
    font-size: .8rem;
    margin-left: 10px!important;
}

.jsa {
    justify-content: space-around;
}

.selectedIcon {
    background-color: var(--p)!important;
    transition: var(--transitionIn);
}

.selectedIcon:hover {
    opacity: .8;
}

.oBlock {
    opacity: 1!important;
}

.oBlock:hover {
    opacity: 1!important;
}

.activebutton {
    background-color: var(--l);
    color: var(--p);
}

.backed {
    background-color: var(--l);
    color: var(--d);
    /*border: var(--border);*/
    border-radius: var(--radius);
    width: auto;
    padding: 10px;
    max-width: 100%;
    /*box-shadow: var(--shadow);*/
}

.smallIMG {
    max-width: 25%;
    max-height: 100px;
}

.jfs {
    justify-content: flex-start;
}

.activeT {
    background-color: var(--g)!important;
    color: var(--d)!important;
    border: var(--lightBorder)!important;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    overflow: hidden;
    padding: 5px;
}



.item .icon.oBlock:hover {
    background-color: var(--l);
}

.item .icon.selectedIcon.oBlock:hover {
    background-color: var(--p);
}




.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 10000;
    left: 50%;
    bottom: 60px;
    font-size: 17px;
    border-radius: 0.3em;
}

.snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 350ms;
    animation: fadein 350ms;
}


div.gallery {
    margin: 5px;
    border: 1px solid var(--l);
    float: left;
    width: 180px;
}

div.gallery:hover {
    border: 1px solid var(--g);
}

div.gallery img {
    width: 100%;
    height: auto;
}

div.desc {
    padding: 15px;
    text-align: center;
}

/* Style the tab */

.tab {
    overflow: hidden;
    border: 1px solid var(--g);
    background-color: var(--l);
}

/* Style the buttons inside the tab */

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: var(--transitionIn);
    font-size: 17px;
}

/* Change background color of buttons on hover */

.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */

.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */

.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid var(--g);
    border-top: none;
    background-color: var(--l);
}
#fileManagerSearch {
    position: absolute;
    top: 5px;
    left: 0;
    width: 38%;
    max-width: 38%;
    z-index: 3;
}
.close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    z-index: 9999;
}


/* custom scrollbar */



#pages span {
    width: 100px;
    max-width: 100%;
}

.gbg {
    border-radius: var(--radius);
  border: var(--border);
  background-color: var(--g);
}

.fa-check {
    color: var(--gr);
}

.status .fa-times {
    color: var(--r);
}

ol, ul {
    padding: 0
}

.spin {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}

.row {
    width: 100%;
}
.cpackage {
    background-color: var(--g);
}
.status {
    position: absolute;
    top: 5px;
    left: 5px;
}

.date {
    text-align: right;
    width: 100%;
    margin: -5px 0;
    padding: 0;
}


tr td {
    text-align: left;
}

.table table {
    width: 100%
}

.table table tr td {
    text-align: left;
    cursor: pointer;
}

.table table tr:hover {
    background: var(--g);
}

.table table tr:hover * {
    color: var(--l);
}

.red-btn {
    background-color: var(--r);
    border-color: var(--r);
}

.grey-btn {
    background-color: var(--b);
    border-color: var(--b);
}

.green-btn {
    background-color: var(--p);
    border-color: var(--s);
}

ul {
    list-style-type: none;
}
#notifcount {
    background-color: var(--g);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    max-width: 50%;
}
#notifcount span {
    color: var(--d);
}
#notifcount strong {
    color: var(--p);
}
#xbutton {
    padding: 0px 5px;
    cursor: pointer;
    justify-self: flex-end;
    margin-left: 3px;
    position: absolute;
    right: 0
}
.homebutton {
    text-transform: uppercase;
    font-size: 1.1rem;
    word-break: keep-all;
    color: var(--d);
    font-weight: 800;
    border: 2px solid var(--g);
    border-radius: var(--radius);
    padding: 8px 30px;
    width: auto;
    max-width: 100%;
    transition: var(--transitionIn);
    cursor: pointer;
}
.homebutton:hover {
    font-size: 1.1rem;
    color: var(--l);
    background-color: var(--p);
    font-weight: 800;
    border: var(--transBorder);
    box-shadow: var(--shadow);
    transition: var(--transitionOut);
}
.fifty {
    width: 50%;
}
ul li {
    text-align: left;
}
.selective {
    padding: 4px 8px;
    border-radius: var(--radius);
    background-color: var(--d);
    border: 1px solid var(--s);
    color: var(--l);
    margin: 6px 0;
    cursor: pointer;
    transition: var(--transitionIn);
}
.userName {
    cursor: pointer;
    transition: var(--transitionIn);
    border-radius: var(--radius);
    border: var(--border)
}
.userName:hover {
    color: var(--l)!important;
    transition: var(--transitionOut);
    background-color: var(--d);
}
.selective:hover{
    background-color: var(--p);
    color: var(--l);
    transition: var(--transitionOut);
}
.selector {
    cursor: pointer;
    padding: 4px;
    margin: 0;
    font-weight: 900;
    transition: var(--transitionOut);
}
.selector:hover {
    transition: var(--transitionIn);
    color: var(--p);
    border-bottom: 1px solid var(--p);
}
/* @media (prefers-color-scheme: dark) {
    :root {
        --d: #ffffff;
        --dw: #000200;
        --p: #0f245b;
        --po: #0f245b80;
        --w: #d67e1e;
        --s: #ba9877;
        --g: #d7d7d7;
        --a: #efefef;
        --b: #333333;
        --r: #dc3545;
        --gr: #48d6b3;
        --f: #EAD87D;
        --l: #333333;
        --delivered: rgb(44, 160, 62);
        --processing: rgb(234, 141, 249);
        --review: rgb(249, 206, 141);
        --marketing: rgb(141, 152, 249);
        --onboarding: #409fae;
        --radius: 8px;
        --border: 2px solid var(--p);
        --lightBorder: 1px solid var(--p);
        --transBorder: 2px solid transparent;
        --shadow: 1px 2px 3px #00000040;
        --transitionIn: 300ms ease-in;
        --transitionOut: 250ms ease-out;
    }
    button, h3, span, h2, p, h1, div {
        color: var(--d)!important;
    }
    #searchbarInput::placeholder {
        color: var(--d);
    }
    .activebutton {
        color: var(--l);
        background-color: var(--d);
    }
    *.active {
        color: var(--l)!important;
        background-color: var(--d)!important;
    }
    #searchbar:before {
        color: var(--d);
    }
} */
.backedw {
    background-color: var(--l);
}
@media screen and (min-width: 1081px) {
    #mobilenav {
        display: none!important;
    }
    #mobNavOpen {
        display: none!important;
    }
    .fixedButton {
        top: 0;
        position: absolute;
        z-index: 1;
        right: 0;
    }
}
@media screen and (max-width: 1080px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
    #nav {
        display: none;
    }
    .c2 {
        grid-template-columns: 1fr;
    }
    .c3 {
        grid-template-columns: 1fr 1fr;
    }
    .c4 {
        grid-template-columns: 1fr 1fr;
    }
    .n form .c2 {
        grid-gap: 0;
    }
    #app {
        width: 100%;
        padding: 0;
    }
    #home {
        grid-template-columns: 1fr;
    }
    .minialert {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 1300px) {
    .c4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
@media screen and (min-width: 650px) {
    .item .icon:hover {
        opacity: .8;
        background-color: var(--p);
    }
    .adminItem .icon:hover {
        opacity: .8;
        background-color: var(--p);
    }
    .item .icon.oBlock:hover {
        background-color: var(--l);
    }
    .item .icon.selectedIcon.oBlock:hover {
        background-color: var(--p);
    }
    .options {
        width: 300px;
        max-width: 33%;
        left: 0px;
        top: 0px;
        position: absolute;
        z-index: 4;
    }
    
}
@media screen and (max-width: 1080px) and (min-width:650px) {
    .fixedButton {
        top: 5px;
        right: 5px;
        z-index: 1;
        position: fixed;
    }
        
    #searchbar:before {
        top: 0.4em;
        right: 1em;
        text-align: end;
        pointer-events: none;
    }
}
@media screen and (max-height: 600px) {
    .navtop {
        margin: 3px 0;
    }
    .navcent {
        margin: 5px 0;
    }
    .navbot {
        margin-top: 0;
    }
    #sidebar button {
        margin: 3px 0;
        padding: 2px 4px;
    }
    .navIcon {
        max-height: 15px;
        max-width: 15px;
    }
}
@media screen and (max-width: 650px) {
    input, textarea, select {
        font-size: 1rem;
    }
    #mobNavOpen {
        font-size: 1rem;
    }
    .grid {
        grid-template-columns: 1fr;
    }
    .options {
        width: auto;
        padding: 10px;
        max-width: 33%;
        min-width: 200px;
        right: 0px;
        top: 0px;
        background-color: var(--p);
        color: var(--l);
        box-shadow: 1px 2px 3px #00000040;
        z-index: 4;
        border-radius: var(--radius);
        border-top-right-radius: 0;
        position: fixed;
    }
    .options #sortingMode .sortGrid button {
        background-color: var(--g)!important;
        color: var(--d);
      }
    .sortGrid button.activebutton {
        background-color: var(--l);
        color: var(--p);
    }
    .sortGrid button {
        color: var(--l);
    }
    #sorter {
        color: var(--l);
    }
    .fixedButton {
        bottom: 5px;
        right: 5px;
        z-index: 1;
        position: fixed;
    }
    input, textarea, select {
        font-size: .9rem;
    }
    .c3 {
        grid-template-columns: 1fr;
    }
    .c2 {
        grid-template-columns: 1fr;
    }
    .fifty .b1 {
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
    #nav #logo {
        width: 70%;
        max-width: 70%;
        overflow: hidden;
    }
    .checklist {
        grid-template-columns: 1fr;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slidedown {
    from {
        opacity: 0;
        transform: translateY(-20vh);
    }
    50% {
        opacity: 1;
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideup {
    from {
        opacity: 0;
        transform: translateY(20vh);
    }
    50% {
        opacity: 1;
    }
    to {
        transform: translateY(0);
    }
}
@-webkit-keyframes fadein {
    from {
        bottom: -60px;
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: -60px;
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        bottom: -60px;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        bottom: -60px;
        opacity: 0;
    }
}

#app, #root {
    background-color: var(--dw);
}


.corner-buttons{
    position:fixed;
    z-index: 1;
    bottom:1em;
    right:1em;
}

.corner-buttons button{
    width:50px;
    height:50px;
    border-radius: 100%;
}

/* 18.8.23 */

.App {
	padding: 50px;
}
.search-filter {
    display: flex;
    justify-content: space-between;
}

.list-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1%;
}

.list-row .p-box {
    flex: 0 0 14.6%;
    background: #ffffff;
    margin: 1%;
    padding: 15px 10px;
    text-align: center;
    cursor: pointer;
}

.list-row .p-box span {
    color: #000200;
    font-size: 13px;
    line-height: 20px;
    margin: 5px 0 0 0;
    padding: 0;
    display: block;
}
.list-row .p-box .img {
    /* filter: brightness(0) saturate(100%) invert(13%) sepia(31%) saturate(3638%) hue-rotate(204deg) brightness(98%) contrast(100%); */
    filter: brightness(0) invert(13%) saturate(100%) sepia(29%) saturate(3311%) hue-rotate(201deg) brightness(98%) contrast(101%);
}

.buttonb3 button+button {
    margin-left: 10px;
}

.category select#categories, .mobsearch #searchkey {
    border-radius: 0;
    font-size: 14px;
    height: 36px;
    min-width: 175px
}

.category label, .mobsearch label {
    margin-right: 10px;
}
.mobsearch {
    margin-left: 30px;
}

.catsearch {
    display: flex;
}
.doctitle input {
    border-radius: 0;
    background: transparent;
}
.drafpub {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.drafpub .b2 {
    flex: 1;
    justify-content: start;
}

/* .bdeditor .rdw-option-wrapper
{
    border: 1px solid #000;
}
.bdeditorbox {
    padding: 20px;
    min-height: 50vh;
    margin-top: 20px;
}

.bdeditorbox {
    padding: 20px;
    min-height: 50vh;
    margin-top: 20px;
} */

.bd_viewmore {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 20px;
}
.bd_viewmore .viewclose{
    margin-left: 10px;
}

.bd_viewmore .c3 .b1 {display: block;margin: 0;}

.bd_viewmore .c3 {
    flex: 1;
}

.bdtype {
    position: relative;
}

.bdtype ul {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.bdtype ul li span button {
    padding: 0;
    border: 0;
    width: 15px;
    font-size: 10px;
    background: red;
    margin-left: 5px;
}

.bdtype ul li span {
    text-transform: capitalize;
    font-size: 13px;
}

.bdtype select {
    min-width: 100% !important;
    margin: 0;
}

.bd_searchinput #searchinput {
    position: relative;
}

.bd_searchinput #searchinput #searchresults {
    position: absolute;
    top: 100%;
}

button.rdw-image-modal-btn {
    background: #0f245b;
    border: 0;
    height: 35px;
}

button.rdw-image-modal-btn:hover {
    background: #000;
    color: #fff;
}
.rdw-image-modal-btn:disabled {
    background: #0f245b!important;
}
.rdw-link-modal-btn:disabled {
    background: #0f245b!important;
    height: 40px;
}
.rdw-link-modal-btn:hover {
    background: #0f245b!important;
    color: #fff;
    height: 40px;
}
.rdw-link-modal-btn {
    background: #0f245b!important;
    height: 40px;
}
.rdw-link-decorator-wrapper span a {
    color: #0f245b!important;
  }
@media screen and (max-width:1024px)  {
.App {
    padding: 30px;
}
.fifty {
    width: 96%;
    
}
.homebutton {
    padding:7px 15px;
}
}
@media screen and (min-width:768px) and (max-width:1024px)  {
    .list-row .p-box {
        flex: 0 0 18%;
    }
}

@media screen and (max-width:767px)  {
    .list-row .p-box {
        flex: 0 0 48%;
    }
    .search-filter, .catsearch {
        display:block !important;
    }   
    .mobsearch {
        margin-left: 0;
    }
    .catsearch {
        margin-top:10px;
    }
    .category label, .mobsearch label {
        display: block;
    }
    .category select#categories, .mobsearch #searchkey {
        min-width: 100%;
    }
    .buttonb3 button {
        margin:6px 6px 0 0 !important;
    }
   
}
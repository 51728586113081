
.checkedoutHD {
  border: 2px solid var(--s);
}


.checkout {
  background-color: var(--l);
  color: var(--p);
  border-radius: var(--radius);
  margin: 1rem;
  padding: 1rem;
  width: auto;
  min-width: 80%;
  transition: var(--transitionIn);
}

.listedHD {
  background-color: var(--l);
  color: var(--p);
  border-radius: var(--radius);
  padding: 1rem;
  width: auto;
  min-width: 80%;
  transition: var(--transitionIn);
  cursor: pointer;
  border: var(--transBorder);
  box-shadow: var(--shadow);
}


.listedHD h3 {
  color: inherit;
  cursor: pointer;
}

.listedHD span {
  cursor: pointer;
  color: inherit;
  font-weight: 800;
}

.listedHD p {
  cursor: pointer;
  color: inherit;
}


#hdNav button {
  border: 1px solid var(--l);
  background-color: var(--p);
  color: var(--l);
  font-size: .9rem;
}


.listedHD:hover {
  border: var(--border);
  background-color: var(--p);
  color: var(--l);
  transition: var(--transitionOut);
}

#hardDrive {
  background-color: var(--p);
  color: var(--l);
}

.removeIcon {
  cursor: pointer;
  transition: var(--transitionIn);
}

.removeIcon:hover {
  transition: var(--transitionOut);
  color: var(--p);
}

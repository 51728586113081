
.uploadList {
  width: auto;
  max-width: 100%;
  min-width: 90%;
  padding: 10px;
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
}

.uploadedFile {
  width: auto;
  padding: 8px;
  border-radius: var(--radius);
  border: var(--border);
  background-color: var(--p);
  color: var(--l);
  box-shadow: var(--shadow);
}


.uploadList {
  width: auto;
  max-width: 100%;
  min-width: 90%;
  padding: 10px;
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
}

.uploadedFile {
  width: auto;
  padding: 8px;
  border-radius: var(--radius);
  border: var(--border);
  background-color: var(--p);
  color: var(--l);
  box-shadow: var(--shadow);
}


.uploadList {
  width: auto;
  max-width: 100%;
  min-width: 90%;
  padding: 10px;
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
}

.uploadedFile {
  width: auto;
  padding: 8px;
  border-radius: var(--radius);
  border: var(--border);
  background-color: var(--p);
  color: var(--l);
  box-shadow: var(--shadow);
}


.myUpload {
  height:100vh;
  width: auto;
  min-width: 90%;
  justify-self: center;
  align-self: center;
  padding: 10px;
  border-radius: var(--radius);
}
.right {
  text-align: right;
  width: 100%;
  margin: -5px 0;
  padding: 0;
}
.myupload {
  background-color: var(--l);
  color: var(--p);
  padding: 8px;
  border: var(--lightBorder);
  width: auto;
  border-radius: var(--radius);
  position: relative;
}

.b1 select {
  width: auto;
  min-width: 80%;
}
.togCont {
    max-width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 5px;
    max-height: 15px;
    padding: 0;
    min-width: 50px;
    border-radius: var(--radius);
    border: var(--lightBorder);
    box-shadow: var(--shadow);
    overflow: hidden;
    cursor: pointer;
}
.tog {
    height: 20px;
    cursor: pointer;
    margin: 0;
    min-width: 60%;
    width: 60%;
    max-width: 60%;
}
.toggler {
    position: absolute;
    margin: 0;
    max-width: 60%;
    height: 20px;
    background-color: var(--l);
    z-index: 1;
    width: 50%;
    cursor: pointer;
}
.togNeg {
    background-color: var(--p);
}
.togPos {
    background-color: var(--s);
}